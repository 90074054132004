<div class="asideBody">
  <div class="asideLogotipo omni-d-flex omni-dFlex-align-items--center">
    <img class="asideLogotipo__img" src="assets/images/logo_omni.png" alt="">
    <h6 class="omni-m-0 font-size-xs omni-color-neutral--gray-500 omni-m-0">Renegociação</h6>
  </div>
  <div class="asideUserInfo omni-d-flex omni-dFlex-align-items--center">
      <div class="asideUserInfo__circle">
        <span class="asideUserInfo__firstName text-bold">{{ getFisrtName(name) }}</span>
      </div>
      <div class="asideUserInfo__name"><span class="omni-color-neutral--gray-600 font-size-small text-bold">Olá,</span> <br><span class="omni-color-neutral--gray-700 font-size-medium text-bold">{{ formatCapitalize(name) }}</span></div>
  </div>
  <p class="asideTitle omni-color-neutral--gray-500 omni-text-bold">Selecione uma opção</p>
  <div class="asideMenu">
    <ul class="asideMenu__body">
      <li class="asideMenuItem omni-d-flex omni-dFlex-justify-content--space-between">
        <div class="omni-d-flex omni-dFlex-align-items">
          <img class="asideMenuItem__icon" src="assets/images/icon_negotiation.png" alt="">
          <a routerLink="/web/dividas" [queryParams]="routerLinkQueryParamsUtm()" [routerLinkActiveOptions]="{exact : true}"  routerLinkActive [ngClass]="{'asideMenuItem__active': verifyPathActiveRoute()}" (click)="onClickRenegotiation()" class="asideMenuItem__link font-size-small text-bold">Renegociação</a>
        </div>
        <div>
          <omnids-svg-icon
            [svgClass]="'asideMenuItem__icon2'"
            [svgName]="'rightArrow'"
          >
          </omnids-svg-icon>
        </div>
      </li>
      <li class="asideMenuItem omni-d-flex omni-dFlex-justify-content--space-between">
        <div class="omni-d-flex omni-dFlex-align-items">
          <img class="asideMenuItem__icon" src="assets/images/icon_help.png" alt="">
          <a routerLink="/web/dividas/preciso-de-ajuda" [queryParams]="routerLinkQueryParamsUtm()" routerLinkActive="asideMenuItem__active" [routerLinkActiveOptions]="{exact : true}" (click)="onClickHelp()" class="asideMenuItem__link font-size-small text-bold">Preciso de ajuda</a>
        </div>
        <div>
          <omnids-svg-icon
            [svgClass]="'asideMenuItem__icon2'"
            [svgName]="'rightArrow'"
          >
          </omnids-svg-icon>
        </div>
      </li>
      <li (click)="confirmLogout()" class="asideMenuItem omni-d-flex omni-dFlex-justify-content--space-between">
        <div class="omni-d-flex omni-dFlex-align-items">
          <img class="asideMenuItem__icon" src="assets/images/logout.png" alt="">
          <a class="asideMenuItem__link font-size-small text-bold">Sair </a>
        </div>
        <div>
          <omnids-svg-icon
            [svgClass]="'asideMenuItem__icon2'"
            [svgName]="'rightArrow'"
          >
          </omnids-svg-icon>
        </div>
      </li>
    </ul>
  </div>
</div>

<script src="main-negotiation-web.component.ts"></script>
<main class="container__grid">
    <div class="menuSidebar">
      <app-menu-aside-negotiation-web [name]="profileName"></app-menu-aside-negotiation-web>
    </div>
    <section class="mainBox">
      <app-menu-negotiation-web-mobile *ngIf="storeStatus?.navbarMobile"></app-menu-negotiation-web-mobile>
      <div class="mainContent">
        <app-navbar-negotiation-web
          *ngIf="storeStatus.navbarVisible"
          [stepNow]="storeStatus.stepNow"
          [progressbar]="storeStatus.progressBar"
          [totalSteps]="'4'"
          [title]="storeStatus?.title"
          >
        </app-navbar-negotiation-web>
        <div [ngClass]="{mainContent__router: storeStatus.stepNow !== 'cartao-credito'}">
          <router-outlet></router-outlet>
        </div>
      </div>
    </section>
</main>

import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { CustomRouter as Router } from "../../core/utils/custom-router";
import {BehaviorSubject, Observable} from 'rxjs';
import {StoreState} from './model/store-status.model';
import {CurrencyPipe, DatePipe, Location} from "@angular/common";
import {InstallmentsContractDept} from './model/contract.model';
import {Offer, Plan} from "./model/offer.model";
import {isAfter} from "date-fns";
import {RecuperaContract} from "./model/recupera/recupera-contract.model";
import {RecuperaSimulations} from "./model/recupera/recupera-simulations.model";

interface ItemCache<T> {
  content: T;
  creationTimestamp: number;
  creationDate: string;
}
@Injectable({
  providedIn: 'root'
})
export class StoreStatusNegotiationService {

  urlBase = 'web/dividas';
  private KEY_SESSION_STORAGE = 'negotiationSimulationSteps';
  private storeState: StoreState = {id: '', contract: {}, stepNow: '', navbarVisible: false, progressBar: false};
  urls: any = {
    'dividas': {text: 'dividas', path: ''},
    'contractid': {text: 'contract-id', path: ''},
    'oferta': {text: 'oferta', path: ''},
    '1': {text: 'valor', path: ''},
    '2': {text: 'parcelamento', path: ''},
    '3': {text: 'vencimento', path: ''},
    '4': {text: 'resumo', path: ''},
    'em-analise': {text: 'em-analise', path: ''},
    'detalhes-divida': {text: 'detalhes-divida', path: ''},
    'mensagem-vencimento': {text: 'mensagem-vencimento', path: ''},
    'acesso-acordo': {text: 'acesso-acordo', path: ''},
    'acesso-acordo/preciso-de-ajuda': {text: 'preciso-de-ajuda', path: 'acesso-acordo/preciso-de-ajuda'},
    'acesso-acordo-parcelas': {text: 'acordo parcelas', path: ''},

  }
  private originDevice: string = '';
  removeMainContentStyle = false;

  private _storeStatus$ = new BehaviorSubject<StoreState>(this.storeState);
  private _storeLogin$ = new BehaviorSubject<boolean>(false);

  constructor(private router: Router,
              private currencyPipe: CurrencyPipe,
              private location: Location,
              private datePipe: DatePipe,) {
  }

  setOriginDevice(): void {
    const pathName = window.location.pathname.split('/')[1];
    this.originDevice = pathName;
    this.urlBase = pathName + '/dividas';
  }

  getOriginDevice(): string {
    return this.originDevice;
  }

  setLoginKeyCloak(value: boolean) {
    this._storeLogin$.next(value);
  }

  getStoreStatus(): Observable<StoreState> {
    return this._storeStatus$.asObservable();
  }

  setStoreStatus(latestValue: any) {
    this.storeState = {...this.storeState, ...latestValue};
    return this._storeStatus$.next(this.storeState);
  }

  updateUrl() {
    Object.keys(this.urls).forEach(key => {
      switch (key) {
        case 'dividas':
          this.urls[key].path = `${this.urlBase}`
          break;
        case 'contractid':
          this.urls[key].path = `${this.urlBase}/simular/${this.storeState.id}`
          break;
        case 'oferta':
          this.urls[key].path = `${this.urlBase}/oferta/${this.storeState.id}`
          break;
        case '1':
          this.urls[key].path = `${this.urlBase}/simular/${this.storeState.id}/valor`
          break;
        case '2':
          this.urls[key].path = `${this.urlBase}/simular/${this.storeState.id}/parcelamento`
          break;
        case '3':
          this.urls[key].path = `${this.urlBase}/simular/${this.storeState.id}/vencimento`
          break;
        case '4':
          this.urls[key].path = `${this.urlBase}/simular/${this.storeState.id}/resumo`
          break;
        case 'em-analise':
          this.urls[key].path = `${this.urlBase}/em-analise/${this.storeState.id}`
          break;
        case 'acesso-acordo':
          this.urls[key].path = `${this.urlBase}/acesso-acordo/${this.storeState.id}`
          break;
        case 'acesso-acordo-parcelas':
          this.urls[key].path = `${this.urlBase}/acesso-acordo/${this.storeState.id}/parcelas`
          break;
        case 'detalhes-divida':
          this.urls[key].path = `${this.urlBase}/acesso-acordo/${this.storeState.id}`
          break;
      }
    });
  }

  retrieveSelectedOffer(contractId: string){
    try {
      return JSON.parse(localStorage.getItem(`selectedOffer${contractId}`) || '{}')
    } catch (e) {
      return null;
    }
  }

  retrieveOffer(contractId: string){
    try {
      return JSON.parse(localStorage.getItem(`offer${contractId}`) || '{}')
    } catch (e) {
      return null;
    }
  }

  saveClientInfo(item: any) {
    localStorage.setItem(`current_client`, JSON.stringify(item));
  }

  retrieveClientInfo() {
    try {
      return JSON.parse(localStorage.getItem(`current_client`) || 'null');
    } catch (e) {
      return null;
    }
  }

  retrieveClientFirstName() {
    try {
      let client = this.retrieveClientInfo();
      if (client?.name) {
        let firstName = client?.name.split(' ')[0];
        return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
      }
    } catch (e) {
      console.log(e);
    }
    return 'Cliente';
  }

  saveOffer(contractId: string, item: any){
    localStorage.setItem(`offer${contractId}`, JSON.stringify(item));
  }

  validateOffer(offer: any, offerId: number, planId: number | null = null) {
    let currentDate = new Date();
    let offerDate = new Date(offer.offerDate);
    return !isAfter(currentDate, offerDate) && offerId == offer.id && (!planId || offer.plans.find((p: any) => p.id === planId));
  }

  buildOffer(activatedRoute: ActivatedRoute, validateSelectedPlan: boolean = false) : Offer {
    let contractId: string;
    let offerId: number;
    let planId: number;
    let offer: any | null;
    let selectedPlanAny: any;
    let selectedPlan: Plan;
    let data = activatedRoute.snapshot.paramMap;

    contractId = data.get('contractId') || '';
    offerId = parseInt(data.get('offerId') || '0');
    planId = parseInt(data.get('planId') || '0');
    offer = this.retrieveOffer(contractId);

    if (!contractId || !offerId || !offer || !this.validateOffer(offer, offerId) || (!planId && validateSelectedPlan)) {
      throw 'not valid pathVariable';
    }
    if (validateSelectedPlan) {
      selectedPlanAny = this.validateOffer(offer, offerId, planId);
    } else {
      selectedPlanAny = offer.defaultPlan;
      planId = selectedPlanAny.id;
    }

    if (!selectedPlanAny) {
      throw 'not valid plan';
    }

    selectedPlan = {
      id: planId,
      discount: selectedPlanAny.discount,
      downPaymentValue: selectedPlanAny.downPaymentValue,
      installments: selectedPlanAny.installments,
      installmentValue: selectedPlanAny.installmentValue,
      total: selectedPlanAny.total,
    }

    const obj = {id: contractId, stepNow: 'oferta', navbarVisible: true, progressBar: false}
    this.setStoreStatus(obj);

    return {
      offerId,
      offer,
      selectedPlan,
      debt: offer.debt,
      contractId,
      expiredDates: offer.expiredDates,
      minInstallmentValue: offer.minInstallmentValue
    };
  }

  goBack() {
    this.updateUrl();
    if (['cartao-credito'].includes(this.storeState.stepNow)) {
      this.location.back();
    } else if (['cartao-credito-outro'].includes(this.storeState.stepNow)) {
      this.location.back();
    } else if (['acesso-acordo/preciso-de-ajuda'].includes(this.storeState.stepNow)) {
      this.location.back();
    } else if (['oferta'].includes(this.storeState.stepNow)) {
      if (this.retrieveSelectedOffer(this.storeState.id)) {
        this.location.back();
      } else {
        this.router.navigate([this.urls['dividas'].path]);
      }
    } else if (['contractid', 'acesso-acordo', 'em-analise'].includes(this.storeState.stepNow)) {
      this.router.navigate([this.urls['dividas'].path]);

    } else if (['detalhes-divida', 'mensagem-vencimento', 'boleto-pagamento', 'acesso-acordo-parcelas'].includes(this.storeState.stepNow)) {
      this.router.navigate([this.urls['acesso-acordo'].path]);

    } else if (this.storeState.stepNow === '1') {
      this.router.navigate([this.urls['contractid'].path]);
    } else {
      this.router.navigate([this.urls[Number(this.storeState.stepNow) - 1].path]);
    }
  }

  goNextStep(step: string) {
    this.updateUrl();
    this.router.navigate([this.urls[step].path]);
  }

  convertStepToPercent(stepNow: number, totalSteps: number) {
    return (100 / totalSteps) * stepNow;
  }

  calcInstallmentsAmount(valueDebit: number, maxAmount: number, debitInstallmentsContractDept: Array<InstallmentsContractDept>, minimumValue: number) {
    const installments = [];
    for (let index = 0; index <= maxAmount; index++) {
      if (0 === index) {
        installments.push({amount: 1, value: valueDebit, description: 'Pagamento à vista'});
      }
      if ([2, 5, 12, 24, 36, 48].includes(index) && this.dateIsGreaterThanOrEqualToDays(debitInstallmentsContractDept, 90)) {
        installments.push(this.formatObjectInstallment(valueDebit, index));
      }
    }
    return installments.filter(installment => installment.value >= minimumValue);
  }

  dateIsGreaterThanOrEqualToDays(installments: InstallmentsContractDept[], periodDays: number) {
    const newArrayInstallments = installments.map(value => value.dueDate).sort((a, b) => a - b);
    const dateNow = Date.now();
    const datePast = newArrayInstallments[0];
    const diff = Math.abs(dateNow - datePast);
    const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
    return days >= periodDays;
  }

  formatObjectInstallment(valueDebit: number, amount: number) {
    return {
      amount: amount,
      value: (valueDebit / amount),
      description: `Total: ${this.currencyPipe.transform((valueDebit))}`
    }
  }

  setSessionStorageStep(storeState: any, id: string) {
    const storage = this.getSessionStorageStep();
    let data: any = storage ? storage : {};
    const expiresIn = Date.now();
    if (!data.expiresIn) {
      data['expiresIn'] = expiresIn;
    }
    data[id] = {...data[id], ...storeState};
    sessionStorage.setItem(this.KEY_SESSION_STORAGE, JSON.stringify(data));
  }

  getSessionStorageStep() {
    const storage: any = sessionStorage.getItem(this.KEY_SESSION_STORAGE);
    if (!storage) return null;
    const data: any = JSON.parse(storage);
    return (data);
  }

  thereIsNegotiation(expiresIn: number) {
    const dateNow = Date.now();
    const valueConvertToMinuto = 60000;
    const timeInMinutes = Math.round((dateNow - expiresIn) / valueConvertToMinuto);
    const isExpired = timeInMinutes >= 60;
    if (isExpired) {
      sessionStorage.removeItem(this.KEY_SESSION_STORAGE);
    }
    return isExpired;
  }

  setSessionStorageContractInfo(contracts: any){
    sessionStorage.setItem('contracts_client',JSON.stringify(contracts));
  }

  getSessionStorageContractInfo(){

      const storage:any = sessionStorage.getItem('contracts_client');
      if(!storage) return null;
      const data:any = JSON.parse(storage);
      return (data);
  }

  getDataRemoveMainContentStyle(): boolean {
    return this.removeMainContentStyle;
  }

  setRemoveMainContentStyle(status: boolean) {
    this.removeMainContentStyle = status;
  }


  private createItemCache<T>(item: T): ItemCache<T> {
    const currentTime = new Date();
    return {
      content: item,
      creationTimestamp: currentTime.getTime(),
      creationDate: this.datePipe.transform(currentTime, 'yyyy-MM-dd') || '',
    }
  }

  /**
   * Valida que a sessão tinha sido feita hoje ou retorna null
   * @param sessionItem
   * @private
   */
  private getItemCache<T>(sessionItem: string) : T | null {
    try {
      const currentDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd') || '';
      const itemCache: ItemCache<T> = JSON.parse(sessionStorage.getItem(sessionItem) || '');
      if(itemCache && itemCache.creationDate === currentDate) {
        return itemCache.content;
      }
    } catch (e) {}
    return null;
  }

  setRecuperaSimulations(simulations: RecuperaSimulations | null, keyClientId: string, keyCreditorId: string, entryDate: string){
    let key = `recupera_simulations_${keyClientId}_${keyCreditorId}_${entryDate}`;
    sessionStorage.setItem(key, JSON.stringify(
      this.createItemCache(simulations)
    ));
  }

  retrieveRecuperaSimulations(keyClientId: string, keyCreditorId: string, entryDate: string) : RecuperaSimulations | null{
    let key = `recupera_simulations_${keyClientId}_${keyCreditorId}_${entryDate}`;
    return this.getItemCache(key);
  }

  setRecuperaContract(contract: RecuperaContract | null, keyClientId: string, keyCreditorId: string) {
    let key = `recupera_contract_${keyClientId}_${keyCreditorId}`;
    sessionStorage.setItem(key, JSON.stringify(
      this.createItemCache(contract)
    ));
  }

  retrieveRecuperaContract(keyClientId: string, keyCreditorId: string) : RecuperaContract | null{
    let key = `recupera_contract_${keyClientId}_${keyCreditorId}`;
    return this.getItemCache(key);
  }

  customLocalStorageClear() {
    for ( var i = 0, len = localStorage.length; i < len; ++i ) {
      let key = localStorage.key(i);
      if (key && !(key.startsWith('FirstCall') || key === 'refused_popup' || key === 'gtmFirstLogin')) {
        localStorage.removeItem(key);
      }
    }
  }
}

<div class="asideMobileBody">
  <div class="asideMobile__iconClose" (click)="close()">
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="18" cy="18" r="18" fill="#F7F7F8"/>
    <path d="M11.333 11.3334L24.6663 24.6667" stroke="#676D79" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M24.6663 11.3334L11.333 24.6667" stroke="#676D79" stroke-width="2.2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
  <div class="asideMobileUserInfo omni-d-flex omni-dFlex-align-items--center">
      <div class="asideMobileUserInfo__circle">
        <span class="asideMobileUserInfo__firstName text-bold">{{ getFisrtName(name) }}</span>
      </div>
      <div class="asideMobileUserInfo__name"><span class="omni-color-neutral--gray-600 font-size-small text-bold">Olá,</span> <br><span class="omni-color-neutral--gray-700 font-size-medium text-bold">{{ formatCapitalize(name) }}</span></div>
  </div>
  <p class="asideMobileTitle omni-color-neutral--gray-500 omni-text-bold">Selecione uma opção</p>
  <div class="asideMobileMenu">
    <ul class="asideMobileMenu__body">
      <li class="asideMobileMenuItem omni-d-flex omni-dFlex-justify-content--space-between">
        <div class="omni-d-flex omni-dFlex-align-items">
          <img class="asideMobileMenuItem__icon" src="assets/images/icon_negotiation.png" alt="">
          <a routerLink="/web/dividas" [queryParams]="routerLinkQueryParamsUtm()" routerLinkActive="asideMobileMenuItem__active" [routerLinkActiveOptions]="{exact : true}"  class="asideMobileMenuItem__link font-size-small text-bold" (click)="onClickRenegotiation()">Renegociação</a>
        </div>
        <div>
          <omnids-svg-icon
            [svgClass]="'asideMobileMenuItem__icon2'"
            [svgName]="'rightArrow'"
          >
          </omnids-svg-icon>
        </div>
      </li>
      <li class="asideMobileMenuItem omni-d-flex omni-dFlex-justify-content--space-between">
        <div class="omni-d-flex omni-dFlex-align-items">
          <img class="asideMobileMenuItem__icon" src="assets/images/icon_help.png" alt="">
          <a routerLink="preciso-de-ajuda" [queryParams]="routerLinkQueryParamsUtm()" routerLinkActive="asideMobileMenuItem__active" class="asideMobileMenuItem__link font-size-small text-bold" (click)="onClickHelp()">Preciso de ajuda</a>
        </div>
        <div>
          <omnids-svg-icon
            [svgClass]="'asideMobileMenuItem__icon2'"
            [svgName]="'rightArrow'"
          >
          </omnids-svg-icon>
        </div>
      </li>
      <li (click)="confirmLogout()" class="asideMobileMenuItem omni-d-flex omni-dFlex-justify-content--space-between">
        <div class="omni-d-flex omni-dFlex-align-items">
          <img class="asideMobileMenuItem__icon" src="assets/images/logout.png" alt="">
          <a class="asideMobileMenuItem__link font-size-small text-bold">Sair </a>
        </div>
        <div>
          <omnids-svg-icon
            [svgClass]="'asideMobileMenuItem__icon2'"
            [svgName]="'rightArrow'"
          >
          </omnids-svg-icon>
        </div>
      </li>
    </ul>
  </div>
</div>

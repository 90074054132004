import {Component, OnInit} from '@angular/core';
import {authCodeFlowConfig} from "../../config/auth/authCodeFlowConfig";
import {JwksValidationHandler} from "angular-oauth2-oidc-jwks";
import {OAuthService} from "angular-oauth2-oidc";
import { CustomRouter as Router } from "../../core/utils/custom-router";
import {environment} from "../../../environments/environment";
import {Loading01Service} from "@omni/cobranca-share";

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  urlLanding: string = '';

  constructor(
    private oAuthService: OAuthService,
    private router: Router,
    private loading01Service: Loading01Service,
  ) {
    this.oAuthService.configure(authCodeFlowConfig);
    this.oAuthService.setStorage(localStorage);
    this.oAuthService.tokenValidationHandler = new JwksValidationHandler();
    this.urlLanding = environment.URL_LANDING;
  }

  async ngOnInit() {
    this.loading01Service.show();
    try {
      await this.oAuthService.loadDiscoveryDocumentAndTryLogin()

      if (this.oAuthService.hasValidIdToken() || this.oAuthService.hasValidAccessToken()) {
        console.log("etapa onInit");
        this.router.navigate(['web/dividas']);
      } else if (this.urlLanding) {
        window.location.href = this.urlLanding;
      }
    } finally {
      this.loading01Service.hide();
    }
  }

  async handleClick() {
    if (this.oAuthService.hasValidIdToken() || this.oAuthService.hasValidAccessToken()) {
      console.log("etapa click");
      this.router.navigate(['web/dividas']);
    } else {
      this.oAuthService.initCodeFlow();
    }
  }

}

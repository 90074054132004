<div class="navbarStatus omni-d-flex omni-dFlex-justify-content--space-between omni-dFlex-align-items--center">
  <div class="navbarStatus__btnBack omni-d-flex omni-dFlex-align-items--center omni-dFlex-justify-content--center"
    (click)="goBack()"
  >
  <omnids-svg-icon
    [svgClass]="'omni-color-neutral--gray-700'"
    [svgName]="'leftArrow'"
    [svgHeight]="'16px'"
    [svgWidth]="'9px'"
  >
  </omnids-svg-icon>
  </div>
  <h2 class="navbarStatus__title text-bold omni-color-neutral--gray-700 omni-font-size-large" *ngIf="title">{{ title }}</h2>
  <omnids-progress-bar01
    *ngIf="progressbar"
    [valueNow]="stepPercent"
    [color1]="'#E95814'"
    [color2]="'#F29B72'"
    [className]="'customNavbar'"
  >
  </omnids-progress-bar01>
  <span class="steps omni-color-neutral--gray-600"
        *ngIf="progressbar"
  >Passo <span class="text-bold">{{stepNow}}</span> de <span class="text-bold">4</span></span>
</div>

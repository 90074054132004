import { Component } from '@angular/core';
import { MyDialogRef } from '@omni/cobranca-share';

@Component({
  selector: 'app-dialog-confirm-exit',
  templateUrl: './dialog-confirm-exit.component.html',
  styleUrls: ['./dialog-confirm-exit.component.scss']
})
export class DialogConfirmExitComponent  {

  constructor(private myDialogRef: MyDialogRef) { }

  exit() {
    this.myDialogRef.close(true);
  }

  cancel() {
    this.myDialogRef.close(false);
  }

}

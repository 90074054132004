import {StoreState} from './../../negotiation/model/store-status.model';
import {Component, OnInit} from '@angular/core';
import {StoreStatusNegotiationService} from '../../negotiation/store-status-negotiation.service';
import {OAuthService} from 'angular-oauth2-oidc';
import {authCodeFlowConfig} from "../../../config/auth/authCodeFlowConfig";
import {JwksValidationHandler} from "angular-oauth2-oidc-jwks";
import {WindowReactNativeWebView} from "../../../core/utils/WindowReactNativeWebView";
import { CustomRouter as Router } from "../../../core/utils/custom-router";

@Component({
  selector: 'app-main-negotiation-web-layout',
  templateUrl: './main-negotiation-web.component.html',
  styleUrls: ['./main-negotiation-web.component.scss'],
})
export class MainNegotiationWebLayoutComponent implements OnInit {
  route: string = '';
  storeStatus: StoreState = {id: '', contract: '', stepNow: '', navbarVisible: false, title: '', navbarMobile: false};
  profileName = '';
  windowReact: WindowReactNativeWebView = window;

  constructor(private storeStatusNegotiationService: StoreStatusNegotiationService,
              private oAuthService: OAuthService,
              private router: Router
  ) {
    if (!this.windowReact.ReactNativeWebView) {
      this.configureWithNewConfigApi()
    }
  }

  private async configureWithNewConfigApi() {
    this.oAuthService.configure(authCodeFlowConfig);
    this.oAuthService.setStorage(localStorage);
    this.oAuthService.tokenValidationHandler = new JwksValidationHandler();

    await this.oAuthService.loadDiscoveryDocumentAndTryLogin();

    if (this.oAuthService.hasValidIdToken() || this.oAuthService.hasValidAccessToken()) {
      const profile: any = this.oAuthService.getIdentityClaims();
      this.profileName = profile['given_name'];
      this.storeStatusNegotiationService.setLoginKeyCloak(true);
      //Automatically refreshing a token when/ before it expires (Code Flow and Implicit Flow)
      this.oAuthService.setupAutomaticSilentRefresh(undefined, 'access_token');
    } else {
      // this.oAuthService.initCodeFlow();
      this.router.navigate(['/']);
    }
  }


  ngOnInit(): void {
    this.handlerDisplayNavbar();
  }

  handlerDisplayNavbar() {
    this.storeStatusNegotiationService.getStoreStatus().subscribe((result: StoreState) => {
      setTimeout(() => {
        this.storeStatus = result;
      }, 100);
      // Promise.resolve().then(()=> this.storeStatus = result);
    });
  }

}

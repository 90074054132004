  import {
  Headerpn01Module,
  Loading01Module,
  OmniCobrancaShareNgModule,
  OmnidsProgressBar01Module,
  OmnidsSvgIconModule,
  SvgIconService
} from '@omni/cobranca-share';
import { leftArrowIcon, editIcon, rightArrowIcon } from '@omni/cobranca-share';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OAuthModule } from 'angular-oauth2-oidc';

import ptBr from '@angular/common/locales/pt';
import {CurrencyPipe, DatePipe, registerLocaleData} from '@angular/common';
import { MainNegotiationMiniappLayoutComponent } from './pages/layouts/negotiation-miniapp/main-negotiation-miniapp.component';
import { FindIconByProductCategoryService } from './share/services/find-icon-by-product-category.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainNegotiationWebLayoutComponent } from './pages/layouts/negotiation-web/main-negotiation-web.component';
import { MenuDesktopMobileComponent } from './pages/layouts/negotiation-web/menu-negotiation-web-mobile/menu-negotiation-web-mobile.component';
import { NavbarNegotiationWebComponent } from './pages/layouts/negotiation-web/navbar-negotiation-web/navbar-negotiation-web.component';
import { StoreStatusNegotiationService } from './pages/negotiation/store-status-negotiation.service';
import {
  MenuAsideNegotiationWebComponent
} from "./pages/layouts/negotiation-web/menu-aside-negotiation-web/menu-aside-negotiation-web.component";
import { WebinInterceptor } from './core/services/web-interceptor.service';
import { LayoutModule } from '@angular/cdk/layout';
import { MenuAsideNegotiationMobileComponent } from './pages/layouts/negotiation-web/menu-aside-negotiation-mobile/menu-aside-negotiation-mobile.component';
import { LandingModule } from './pages/landing/landing.module';
import {DataLayerService} from "./core/services/data-layer.service";
import {WindowReferenceService} from "./core/services/window-reference.service";

registerLocaleData(ptBr);

@NgModule({
  declarations: [
    AppComponent,
    MainNegotiationMiniappLayoutComponent,
    MainNegotiationWebLayoutComponent,
    MenuDesktopMobileComponent,
    NavbarNegotiationWebComponent,
    MenuAsideNegotiationWebComponent,
    MenuAsideNegotiationMobileComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    OmniCobrancaShareNgModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: ['https://none'],
        sendAccessToken: true,
      },
    }),
    OmnidsSvgIconModule,
    Loading01Module,
    Headerpn01Module,
    OmnidsProgressBar01Module,
    LayoutModule,
    LandingModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    CurrencyPipe,
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
    { provide: '', useValue: 'teste'},
    FindIconByProductCategoryService,
    StoreStatusNegotiationService,
    { provide: HTTP_INTERCEPTORS,
      useClass: WebinInterceptor,
      multi: true
    },
    DatePipe,
    WindowReferenceService,
    DataLayerService,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule {
  constructor(
    private svgIconService: SvgIconService,
  ) {
    this.svgIconService.registerIcons([
      leftArrowIcon,
      rightArrowIcon,
      editIcon
    ]);
  }
}

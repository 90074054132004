<nav class="menuWebMobile">
  <div class="navbarStatus__btnBack omni-d-flex omni-dFlex-align-items--center omni-dFlex-justify-content--center"
       (click)="goBack()" *ngIf="windowReact.ReactNativeWebView"
  >
    <omnids-svg-icon
      [svgClass]="'omni-color-neutral--gray-700'"
      [svgName]="'leftArrow'"
      [svgHeight]="'16px'"
      [svgWidth]="'9px'"
    >
    </omnids-svg-icon>
  </div>
  <div class="omni-d-flex omni-dFlex-align-items--center">
    <img class="menuWebMobile__logo"  src="assets/images/logo.png" alt="Omini Financeira">
    <h3 class="menuWebMobile__title">Renegociação</h3>
  </div>
  <div class="menuWebMobile__circle" (click)="openMenuSideBar()">
    <span></span>
    <span></span>
    <span></span>
  </div>
</nav>

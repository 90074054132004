import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingComponent } from './landing.component';
import { HeroComponent } from './components/hero/hero.component';
import { StepsComponent } from './components/steps/steps.component';
import { AdvantagesComponent } from './components/advantages/advantages.component';
import { CtaComponent } from './components/cta/cta.component';
import { FaqComponent } from './components/faq/faq.component';
import { AccordionModule } from '../../components/accordion/accordion.module';
import { HeaderComponent } from "../../components/layout/header/header.component";
import { FooterComponent } from "../../components/layout/footer/footer.component";



@NgModule({
  declarations: [
    LandingComponent,
    HeroComponent,
    StepsComponent,
    AdvantagesComponent,
    CtaComponent,
    FaqComponent,
    HeaderComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    AccordionModule
  ],
  exports: [
    LandingComponent
  ]
})
export class LandingModule { }

<router-outlet></router-outlet>
<div id="popupPdf" class="popupPdf">
  <div class="popupPdfContainer">
    <div class="popupPdfToolbar">
      <button title="Zoom Out" id="zoomOut" (click)="zoomOut(undefined)">
        <i class="fa fa-search-minus" aria-hidden="true"></i>
      </button>
      <input id="pdfZoom" disabled>
      <button title="Zoom In" id="zoomIn" (click)="zoomIn(undefined)">
        <i class="fa fa-search-plus" aria-hidden="true"></i>
      </button>
      <button title="Previous Page" id="previous" (click)="previousPage()" disabled>
        <i class="fa fa-arrow-up" aria-hidden="true"></i>
      </button>
      <input type="number" id="pageNumber" disabled value="1">
      <button title="Next Page" id="next" (click)="nextPage()">
        <i class="fa fa-arrow-down" aria-hidden="true"></i>
      </button>
    </div>

    <div id="viewerContainer" class="viewerContainer">
      <div id="viewer" class="pdfViewer"></div>
    </div>
    <div class="popupPdfToolbar">
      <button title="Download" (click)="download()">
        <i class="fa fa-download" aria-hidden="true"></i> Baixar PDF
      </button>
      <button title="Close" (click)="closePopupPdf()">
        <i class="fa fa-window-close" aria-hidden="true"></i> Fechar
      </button>
    </div>
  </div>
</div>


import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {StoreStatusNegotiationService} from 'src/app/pages/negotiation/store-status-negotiation.service';

@Component({
  selector: 'app-navbar-negotiation-web',
  templateUrl: './navbar-negotiation-web.component.html',
  styleUrls: ['./navbar-negotiation-web.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarNegotiationWebComponent implements OnInit {

  stepPercent = 0;
  _stepNow = 1;

  @Input() totalSteps:number | string = '';
  @Input() title: any = '';
  @Input()
    set stepNow(value:any) {
      this._stepNow = value;
      this.stepPercent = this.storeStatusNegotiationService.convertStepToPercent(Number(value), 4)
    }
    get stepNow():any {
      return this._stepNow;
    }
  @Input() progressbar: boolean | undefined =false;

  constructor(private storeStatusNegotiationService: StoreStatusNegotiationService,
    private ref: ChangeDetectorRef) { }

  ngOnInit(): void {

  }

  goBack() {
    this.storeStatusNegotiationService.goBack();
  }
}

import { CustomRouter as Router } from "../../core/utils/custom-router";
import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {OAuthService} from 'angular-oauth2-oidc';
import {WindowReactNativeWebView} from "../utils/WindowReactNativeWebView";
import {StoreStatusNegotiationService} from "../../pages/negotiation/store-status-negotiation.service";

@Injectable()
export class WebinInterceptor implements HttpInterceptor {

  windowReact: WindowReactNativeWebView = window;

  constructor(private router: Router,
              private oAuthService: OAuthService,
              private storeStatusNegotiationService: StoreStatusNegotiationService,) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const tokenTest = this.windowReact.ReactNativeWebView ? localStorage.getItem('reactNativeExternalToken') : this.oAuthService.getAccessToken();
    const isUrlKeycloak = /\/auth\/realms\/cliente-omni/i;
    const isUrlRecuperaPdf = /\/recupera\/.*\/pdf/i;

    const request = isUrlRecuperaPdf.test(req.url) ? req.clone({
      setHeaders: {
        'Content-Type': `application/pdf`,
        'Authorization': `Bearer ${tokenTest}`
      }
    }) : !isUrlKeycloak.test(req.url) ? req.clone({
      setHeaders: {
        'Content-Type': `application/json`,
        'Authorization': `Bearer ${tokenTest}`
      }
    }) : req;

    return next
      .handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error instanceof HttpErrorResponse) {
            switch (error.status) {
              case 401:
                if (this.windowReact.ReactNativeWebView) {
                  this.windowReact.ReactNativeWebView?.postMessage(JSON.stringify({command: 'logout401'}));
                } else {
                  this.oAuthService.logOut();
                  this.storeStatusNegotiationService.customLocalStorageClear();
                }
                break;
              case 0:
                break;

              default:
                break;
            }
          }
          return throwError(error)
        })
      )
  }
}

import {OverlayRef} from '@angular/cdk/overlay';
import {Component, OnInit} from '@angular/core';
import {OAuthService} from 'angular-oauth2-oidc';
import { MyDialogConfig, OmnidsDialogService } from '@omni/cobranca-share';
import {WindowReactNativeWebView} from "../../../../core/utils/WindowReactNativeWebView";
import { DialogConfirmExitComponent } from '../dialog-confirm-exit/dialog-confirm-exit.component';
import {StoreStatusNegotiationService} from "../../../negotiation/store-status-negotiation.service";
import {DataLayerService} from "../../../../core/services/data-layer.service";
import {getQueryParamsUTMObject} from "../../../../config/auth/authCodeFlowConfig";

@Component({
  selector: 'app-menu-aside-negotiation-mobile',
  templateUrl: './menu-aside-negotiation-mobile.component.html',
  styleUrls: ['./menu-aside-negotiation-mobile.component.scss']
})
export class MenuAsideNegotiationMobileComponent implements OnInit {

  name = '';
  windowReact: WindowReactNativeWebView = window;

  constructor(private overlayRef: OverlayRef,
              private oAuthService: OAuthService,
              private omnidsDialogService: OmnidsDialogService,
              private storeStatusNegotiationService: StoreStatusNegotiationService,
              private _dataLayerService: DataLayerService,) {
  }

  ngOnInit(): void {
    this.getName();
  }

  confirmLogout() {
    //GTM:ID30
    this._dataLayerService.logEvent({
      event: 'menu',
      event_category: 'click',
      event_label: 'Sair',
      eventAction: window?.location?.href,
    });
    const dialogConfig: MyDialogConfig = {};
    const dialogRef = this.omnidsDialogService.open(dialogConfig, DialogConfirmExitComponent);
    dialogRef.afterClosed.subscribe(result => {
      if (result) {
        this.logout();
      }
    });
  }

  logout() {
    if (this.windowReact.ReactNativeWebView) {
      this.windowReact.ReactNativeWebView?.postMessage(JSON.stringify({command: 'logout'}));
    } else {
      this._dataLayerService.logLogoutEvent((this.oAuthService.getIdentityClaims() as any)['preferred_username'] || '');
      this.oAuthService.logOut();
      this.storeStatusNegotiationService.customLocalStorageClear();
    }
  }

  getFisrtName(name: string) {
    return name ? name.slice(0, 1) : '';
  }

  formatCapitalize(name: string) {
    return name
      ? name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()
      : '';
  }

  close() {
    this.overlayRef.dispose();
  }

  getName() {
    const userName = localStorage.getItem('id_token_claims_obj');
    if (userName) {
      this.name = JSON.parse(userName).given_name;
    }
  }

  onClickRenegotiation() {
    //GTM:ID30
    this._dataLayerService.logEvent({
      event: 'menu',
      event_category: 'click',
      event_label: 'Renegociação',
      eventValue: '/web/dividas',
      eventAction: window?.location?.href,
    });
  }

  onClickHelp() {
    //GTM:ID30
    this._dataLayerService.logEvent({
      event: 'menu',
      event_category: 'click',
      event_label: 'Preciso de ajuda',
      eventValue: '/web/dividas/preciso-de-ajuda',
      eventAction: window?.location?.href,
    });
  }

  routerLinkQueryParamsUtm() {
    return getQueryParamsUTMObject();
  }
}

import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import {ComponentPortal, PortalInjector} from '@angular/cdk/portal';
import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {MenuAsideNegotiationWebComponent} from '../menu-aside-negotiation-web/menu-aside-negotiation-web.component';
import {Subscription} from 'rxjs';
import {
  MenuAsideNegotiationMobileComponent
} from '../menu-aside-negotiation-mobile/menu-aside-negotiation-mobile.component';
import {WindowReactNativeWebView} from "../../../../core/utils/WindowReactNativeWebView";

@Component({
  selector: 'app-menu-negotiation-web-mobile',
  templateUrl: './menu-negotiation-web-mobile.component.html',
  styleUrls: ['./menu-negotiation-web-mobile.component.scss']
})
export class MenuDesktopMobileComponent implements OnInit {


  subscription!: Subscription;
  overlayRef!: OverlayRef;
  @ViewChild(MenuAsideNegotiationWebComponent) menuSidebar: any;
  windowReact: WindowReactNativeWebView = window;

  constructor(private overlay: Overlay,
              private injector: Injector,
              private breakpointObserver: BreakpointObserver) {
  }

  ngOnInit(): void {
    this.handleChangeMediaQueries();
  }

  openMenuSideBar() {
    const positionStrategy = this.overlay
      .position()
      .global()
      .right()
      .top();

    const dialogConfig: OverlayConfig = {
      positionStrategy,
      panelClass: '',
      height: '100vh',
      width: '320px',
      hasBackdrop: true,
      disposeOnNavigation: true
    }
    this.overlayRef = this.overlay.create(dialogConfig);
    const injector = this.createInjector(this.overlayRef)
    const portalMenuAsideComponent = new ComponentPortal(MenuAsideNegotiationMobileComponent, null, injector);
    this.overlayRef.attach(portalMenuAsideComponent)//.instance.addStyleMobile();
    this.overlayRef.backdropClick().subscribe(() => {
      this.overlayRef.detach();
    });

  }

  handleChangeMediaQueries() {
    this.breakpointObserver
      .observe(['(min-width: 992px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          if (this.overlayRef instanceof OverlayRef) {
            this.overlayRef.detach();
          }
        }
      });
  }

  // Create incjector to be able to reference the DialogRef from within the component
  createInjector(dialogRef: OverlayRef): PortalInjector {
    const injectionTokens = new WeakMap();
    injectionTokens.set(OverlayRef, dialogRef);
    return new PortalInjector(this.injector, injectionTokens);
  }

  goBack() {
    this.windowReact.ReactNativeWebView?.postMessage(JSON.stringify({command: 'logout'}));
  }

}
